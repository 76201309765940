import { Spin } from "antd";
import styled from "styled-components";

const PageLoagingStyle = styled.div`
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

const PageLoading = () => {
    return(
        <PageLoagingStyle>
            <Spin size="large" />
        </PageLoagingStyle>
    )
}

export default PageLoading