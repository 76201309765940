import { useState } from "react";

import { Modal, Button, Col, Row, Space } from "antd";

import {
  FacebookIcon,
  YoutubeIcon,
  InstagramIcon,
  TwitterIcon,
} from "../Components/SvgIcons";

import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { availableLanguages } from "../configurations/i18n";
import changeLanguage from '../modules/User/changeLanguage'

const Footer = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChangeLanguage = (lang) => {
    changeLanguage(lang);
    handleCancel();
  };

  return (
    <Row className='footerContain'>
      <Col xs={24} sm={24} md={12} lg={12} xl={12} className='footerColLeft'>
        <span>&#169;</span> 2021 Educsys Meet, All rights reserved{" "}
        <span>&#183;</span> Terms | Privacy | Cookies
      </Col>

      <Col xs={24} sm={24} md={12} lg={12} xl={12} className='footerColRight' >

        <Space size={10} align='start' direction='horizontal'>

          <Button type='link' onClick={showModal}>

            {
              t(i18next.language.toUpperCase())
            }

          </Button>

          <Modal
            footer={null}
            title='Select Language'
            visible={isModalVisible}
            maskClosable={true}
            onCancel={handleCancel}
          >

            <Space>

              {
                availableLanguages.map((e) => {
                  return (
                    <Button
                      key={e}
                      type={i18next.language === e && "primary"}
                      onClick={() => onChangeLanguage(e)}
                    >
                      {
                        t(e.toUpperCase())
                      }
                    </Button>
                  );
                })}
            </Space>
          </Modal>


          <Button
            type='link'
            target='_blank'
            href='https://www.facebook.com/meetsys'
          >
            <FacebookIcon />
          </Button>


          <Button
            type='link'
            target='_blank'
            href='https://www.instagram.com/meetsysde/'
          >

            <InstagramIcon />

          </Button>


          <Button type='link' target='_blank' href='https://twitter.com'>

            <TwitterIcon />

          </Button>


          <Button type='link' target='_blank' href='https://youtube.com'>

            <YoutubeIcon />

          </Button>

        </Space>

      </Col>

    </Row>
  );
};

export default Footer;
