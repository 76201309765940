export const SET_SITE_WIDTH = "SET_SITE_WIDTH";
export const RESET_SITE_WIDTH = "RESET_SITE_WIDTH";
export const LOGIN = "LOGIN";
export const UPDATE_USER = "UPDATE_USER";
export const DEFINE_TOKEN = "DEFINE_TOKEN";
export const LOGOUT = "LOGOUT";
export const SET_MEETING_URL = "SET_MEETING_URL";
export const SET_MEETING_MINIMIZE = "SET_MEETING_MINIMIZE";
export const CHANGE_EXSIDEBAR = "CHANGE_EXSIDEBAR";
export const CHANGE_CALENDAR_VIEW = "CHANGE_CALENDAR_VIEW";


//export default actions;