import { Route, Redirect, Switch } from "react-router-dom";
import React from "react";
import asyncComponent from "../modules/AsyncFunc";
import authRoutes from "./authRoutes";

const routes = [
  {
    exact: true,
    path: "/join/:_meeting_id/:_pass/",
    component: asyncComponent(() => import("../pages/JoinMeeting/index")),
  },
  {
    exact: true,
    path: "/join/:_meeting_id/",
    component: asyncComponent(() => import("../pages/JoinMeeting/index")),
  },
  {
    exact: true,
    path: "/join",
    component: asyncComponent(() => import("../pages/JoinMeeting/index")),
  },
  {
    exact: true,
    path: "/meeting/:_meeting_id",
    component: asyncComponent(() => import("../pages/Meeting")),
  },
  {
    exact: true,
    path: "/meeting/:_meeting_id/:_pass",
    component: asyncComponent(() => import("../pages/Meeting")),
  },
  {
    exact: true,
    path: "/r",
    component: asyncComponent(() => import("../pages/UserRoom")),
  },
  {
    exact: true,
    path: "/r/:id",
    component: asyncComponent(() => import("../pages/UserRoom")),
  },
  {
    exact: true,
    path: "/r/:id/:pass",
    component: asyncComponent(() => import("../pages/UserRoom")),
  },
  {
    exact: true,
    path: "/r/:id/:pass/:faststart",
    component: asyncComponent(() => import("../pages/UserRoom")),
  },
];

const AppRoutes = () => {
  const token = localStorage.getItem("token");
  return (
    <Switch>
      <Route
        exact
        path={"/"}
        component={asyncComponent(() => import("../pages/Home/home"))}
      />
      <Route
        exact
        path={"/signin"}
        component={asyncComponent(() => import("../pages/Signin/signin"))}
      />
      <Route
        exact
        path={"/signup"}
        component={asyncComponent(() => import("../pages/Signup/signup"))}
      />
      {routes.map((r, i) => {
        return <Route key={i} {...r} />;
      })}

      {authRoutes.map((r, i) => {
        return (
          <Route key={i} {...r}>
            {
              !token && process.env.NODE_ENV !== "development" ? <Redirect to="/signin" /> : null}
          </Route>
        );
      })}

      <Route component={asyncComponent(() => import("../pages/404.js"))} />

    </Switch>
  );
};
export default AppRoutes;
