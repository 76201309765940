import React, { Component } from "react";
import { myMeetingURL, profileURL } from "../../configurations/api_paths.js";
import postaci from "../../modules/postaci.js";
import moment from "moment";
import deleteMeeting from "../Responses/deleteMeeting";
import reducer from "./reducer";
import PageLoading from "../../UI/PageLoading/PageLoading";

import { LOGIN, LOGOUT } from "./actions.js";

export const UserContext = React.createContext();

export class UserProvider extends Component {
  state = {
    appLoading: true,
    appLoadingError: null,
    user: null,
    dispatch: (action) => {
      this.setState((state) => reducer(state, action));
    },
    token: null,
    meeting_url: null,
    meeting_minimize: false,
    siteWidth: null, // tema degiskenlerinin icinde
    exSideBar: false,
    meetings: null,
    meetingsSet: {
      start: new moment().startOf("month").toDate(),
      end: new moment().endOf("month").toDate(),
      limit: 10,
      page: 1,
      view: "week",
    },
    deleteMeeting: (id) =>
      deleteMeeting({
        id,
        setState: this.setState,
      }),
  };

  componentDidMount = async () => {
    try {
      const token = localStorage.getItem("token");
      let user = JSON.parse(localStorage.getItem("user"));
      if (token) {
        await this.getMeetings();
        const userData = await postaci.get({
          url: profileURL,
        }); //JSON.parse(localStorage.getItem('user'))

        if (userData) {
          user = userData.data;
        }

        this.setState({
          type: LOGIN,
          user,
          appLoading: false,
          token,
        });
      } else {
        this.setState({
          appLoading: false,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        appLoadingError: "Error",
      });
      this.state.dispatch({
        type: LOGOUT,
      });
    }
  };

  getMeetings = async () => {
    //setloading(true)
    const options = {
      url: myMeetingURL,
      data: {
        start: this.state.meetingsSet.start,
        end: this.state.meetingsSet.end,
      },
      dispatch: this.state.dispatch,
    };
    const up_m = await postaci.post(options);
    if (up_m) {
      let events = [];
      up_m.data.map((m) => {
        if (m.events.length > 0) {
          m.events.map((e) => {
            const start = e.start && moment(e.start);
            const duration =
              e.BBB && e.BBB.duration
                ? e.BBB.duration
                : e.duration
                ? e.duration
                : m.BBB && m.BBB.duration && m.BBB.duration;
            const end = moment(start).add(duration, "minutes");
            const description = e.description ? e.description : m.description;
            if (start && duration && end)
              events[events.length] = {
                id: e._id,
                title: e.title ? e.title : m.title,
                start: start.toDate(),
                end: end.toDate(),
                duration,
                color: m.color,
                description,
                meeting_id: m._id,
              };
          });
        }
      });
      this.setState({
        meetings: events.sort((a, b) => Date(a) - Date(b)) ?? [],
      });

      //setMeetings()
    }
  };

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.state.appLoadingError ? (
          this.state.appLoadingError
        ) : this.state.appLoading ? (
          <PageLoading />
        ) : (
          this.props.children
        )}
      </UserContext.Provider>
    );
  }
}
const UserConsumer = UserContext.Consumer;

export default UserConsumer;
