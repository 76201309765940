import React, { useContext, useState } from "react";
import PageLoading from "../UI/PageLoading/PageLoading";
const AppContext = React.createContext();
const AppUpdateContext = React.createContext();

export function AppProvider({ children }) {

    // !! if app version insert, 
    const [appLoading, setAppLoading] = useState(false);
    const [user, setUser] = useState();
    const [token, setToken] = useState(null);

    const login = (cToken,cUser) =>{
        setToken(cToken)
        setUser(cUser)
    }

    return (
        <AppContext.Provider value={
            {
                appLoading,
                user,
                token
            }
        }>
            <AppUpdateContext.Provider value={
                {
                    login
                }
            }>
                {
                    appLoading ?
                        <PageLoading />
                        :
                        children
                }
            </AppUpdateContext.Provider>
        </AppContext.Provider>
    );
}

export function useAppState() {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error("useAppState can only be used inside AppContext");
    }
    return context;
}

export function useAppStateUpdate() {
    const context = useContext(AppUpdateContext);
    if (context === undefined) {
        throw new Error("useAppStateUpdate can only be used inside AppUpdateContext");
    }
    return context;
}