import {
  SET_SITE_WIDTH,
  LOGIN,
  LOGOUT,
  UPDATE_USER,
  DEFINE_TOKEN,
  SET_MEETING_URL,
  SET_MEETING_MINIMIZE,
  RESET_SITE_WIDTH,
  CHANGE_EXSIDEBAR,
  CHANGE_CALENDAR_VIEW,
} from "./actions.js";

// Provider , Consumer
const reducer = (state, action) => {
  switch (action.type) {
    case CHANGE_EXSIDEBAR:
      return {
        ...state,
        exSideBar: action.payload,
      };
    case SET_SITE_WIDTH:
      return {
        ...state,
        siteWidth: action.payload,
      };
    case RESET_SITE_WIDTH:
      return {
        ...state,
        siteWidth: null,
      };
    case LOGIN:
      localStorage.setItem("token", action.token);
      localStorage.setItem("user", JSON.stringify(action.user));
      return {
        ...state,
        user: action.user,
        token: action.token ? action.token : state.token,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case DEFINE_TOKEN:
      localStorage.setItem("token", action.payload);
      return {
        ...state,
        token: action.payload,
      };
    case LOGOUT:
      console.log("LOGOUT");
      localStorage.clear();
      return {
        ...state,
        token: null,
        user: null,
      };
    case SET_MEETING_URL:
      return {
        ...state,
        meeting_url: action.payload,
      };
    case SET_MEETING_MINIMIZE:
      return {
        ...state,
        meeting_minimize: action.payload,
      };
    case CHANGE_CALENDAR_VIEW:
      return {
        ...state,
        meetingsSet: {
          ...state.meetingsSet,
          view: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
