import styled from "styled-components";
import { borderRadius, boxShadow } from "../configurations/style-util";
import { palette } from "styled-theme";

const footerHeight = 70;

export const SigninStyle = styled.div`
  .login-form-button {
    width: 100%;
    margin-top: 15px;
  }
`;


const AppStyle = styled.div`
  height: 100%;

  .siteContainer {
    min-height: calc(100vh - ${footerHeight}px);
  }

  .bodyContainer {
    width: 100%;
  }

  .exSideBar {
    width: 500px;
    display: inline-table;
  }

  .body {
    justify-content: center;
    display: flex;
    padding-top: 80px;

    .mainContainer {
        max-width: ${palette('sizes', 0)}px;
        width:100%;
        padding: 30px;    
    }

    
}

  .subArea {
    font-weight: 600;
    font-size: 16pt;
  }

  .ant-btn-primary:not([disabled]) {
    background-color: ${palette("primary", 0)};
    border-color: ${palette("primary", 0)};
  }

  .ant-btn {
    ${borderRadius("8px")};
  }

  .ant-input,
  .ant-input-affix-wrapper,
  .ant-input-number,
  .ant-picker {
    ${borderRadius("8px")};
    border-width: 2px;
  }

  .menu {
    ul {
      display: flex;
      list-style: none;
      justify-content: space-between;
    }
  }
  .ant-form-inline .ant-form-item-with-help {
    margin-bottom: 0px;
  }
  .panel {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }

  .footer {
    justify-content: center;
    width: 100%;
    display: flex;
    height: ${footerHeight}px;

    .footerContain {
        display: flex;
        max-width: ${palette('sizes', 0)}px;
        width:100%;

        .footerColLeft {
          
        }
        
        .footerColRight {
        display: flex;
        justify-content: flex-end;
        }

        @media only screen and (max-width: 768px) {
          .footerColRight {
            justify-content: center;
          }
          .footerColLeft {
            text-align: center;
          }
        }
    }
}
`

const HeaderStyle = styled.header`
    ${boxShadow('0px 0.5px 4px rgba(0, 0, 0, 0.12)')};
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color:white;
    z-index:3;
    justify-content: center;
    display:flex;

    .logo {
        min-width: 200px;
        width: 30%;
    }

    .navbar {
        display: flex;
        max-width: ${palette('sizes', 0)}px;
        width:100%;
        padding:15px;
    }

    .mainmenu {
        width: 70%;
        text-align: right;
        position: relative;
    }

    .mainmanuarea {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
    }

    .avatar {
      cursor: pointer;
    }
`;


const FooterStyle = styled.div``;

const ExSideBarStyle = styled.div`
  width: inherit;
  position: fixed;
  top: 0px;
  bottom: 0px;
  overflow-y: scroll;
`;

export {  HeaderStyle, ExSideBarStyle, FooterStyle };
export default AppStyle;
