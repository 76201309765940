import axios from "axios";
import { message } from "antd";
import StatusCodes from "../configurations/defines/status_code";
import { LOGOUT } from "../Context/Auth/actions";
const postaci = {
  post: async ({ intoken = true, url, data, dispatch }) => {
    const token = localStorage.token;
    try {
      let obj = {
        url,
        data,
        method: "post",
      };

      if (intoken && token) {
        obj = {
          ...obj,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      } else {
      }
      const res = await axios({ ...obj });
      return res;
    } catch (e) {
      ErrorHanle(e, dispatch);
    }
  },
  get: async ({ intoken = true, url, dispatch }) => {
    const token = localStorage.token;
    try {
      let obj = {
        url,
        method: "get",
      };
      if (intoken) {
        obj = {
          ...obj,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      } else {
      }
      const res = await axios({ ...obj });

      return res;
    } catch (e) {
      ErrorHanle(e, dispatch);
    }
  },
  delete: async ({ intoken = true, url, dispatch }) => {
    const token = localStorage.token;
    try {
      let obj = {
        url,
        method: "delete",
      };
      if (intoken) {
        obj = {
          ...obj,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      } else {
        obj = {
          ...obj,
          headers: {
            Authorization: null,
          },
        };
      }
      const res = await axios({ ...obj });
      return res;
    } catch (e) {
      ErrorHanle(e, dispatch);
    }
  },
};

const ErrorHanle = (error, dispatch) => {
  if (error.response) {
    const { status } = error.response;
    if (status === StatusCodes.BAD_REQUEST) {
      if (error.response.data) {
        message.error(error.response.data.error);
      }
    } else if (status === 500) {
      console.log("deneme");
    } else if (status === StatusCodes.UNAUTHORIZED) {
      console.log("LOGOUT");
      dispatch({
        type: LOGOUT,
      });
    }
  } else if (error.message) {
    message.error(error.message);
  }
  return null;
};

export default postaci;
/* 
const handleError = (error: any) => {
    if (error.response) {
      if (error.response.status === Status.BAD_REQUEST) {
        if (error.response.data) {
          const index = error.response.data.indexOf(':')
          const code = (index !== -1) ? error.response.data.substring(0, index) : null
          const message = (index !== -1) ? error.response.data.substring(index + 1).trim() : error.response.data
          return Promise.reject(new BadRequestError(code, message))
        }
      }
  
      else if (error.response.status === Status.UNAUTHORIZED) {
        return Promise.reject(new AuthError())
      }
  
      else if (error.response.status === Status.NOT_FOUND) {
        if (error.config.method === 'get' || error.config.method === 'delete') {
          error.response.data = null
          return Promise.resolve(error.response)
        }
      }
    }
  
    return Promise.reject(error)
  } */
