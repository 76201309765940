import { UserContext } from "../Context/Auth";
import { useContext } from "react"
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Button, Space, Dropdown, Menu, Typography } from "antd";
import { HeaderStyle } from './style';
import Avatar from "antd/lib/avatar/avatar";
import { LOGOUT } from "../Context/Auth/actions";
import nameShorter from "../modules/nameShorter";
import { useAppState } from "../Context/AppProvider";


const Header = () => {
    const { token, user } = useAppState()
    
    function logout () {
        
    }

    const { t } = useTranslation();

    const menu = (
        <Menu>
            <Menu.Item>
                <Link to="/profile">{t("PROFILE")}</Link>
            </Menu.Item>
            <Menu.Item>
                <Typography.Link 
                onClick={logout}>{t("LOGOUT")}</Typography.Link>
            </Menu.Item>
        </Menu>
    );

    return (
        <HeaderStyle>
            <nav className="navbar">
                <div className="logo">
                    <Link to={token ? "/dashboard" : "/"}><img width={200} alt="meetSYS" src="/logo.png" /></Link>
                </div>
                <div className="mainmenu">
                    <div className="mainmanuarea">
                        {
                            !token
                                ?
                                //-------- not Auth ----------------

                                <Space size={10}>

                                    <Link to="/signup">
                                        <Button size="large">{t("SIGNUP")}</Button>
                                    </Link>

                                    <Link to="/signin">
                                        <Button size="large" type="primary">{t("SIGNIN")}</Button>

                                    </Link>

                                </Space>
                                :
                                //----------- Auth -------------
                                <div>
                                    <Dropdown trigger={['click']} overlay={menu} placement="bottomRight" arrow>
                                        <Avatar className="avatar" size="large">{nameShorter(user.display_name)}</Avatar>
                                    </Dropdown>
                                </div>
                        }
                    </div>
                </div>
                {/* <nav>
                <ul>
                    <li><Link to="/">{t("HOME")}</Link></li>
                    {token ?
                        <>
                            <li><Link to="/dashboard">Dashboard</Link></li>
                            <li><Link to="/join">{t("JOINAMEETING")}</Link></li>
                            <li><Link to="/createameeting">{t("CREATEAMEETING")}</Link></li>
                            <li>
                                <a onClick={
                                    () =>
                                        dispatch({
                                            type: LOGOUT
                                        })
                                }>Logout</a></li>
                        </>
                        :
                        <>
                            <li><Link to="/signin">Signin</Link></li>
                            <li><Link to="/register">Create User</Link></li>
                        </>
                    }

                </ul>
            </nav> */}
            </nav>
        </HeaderStyle>
    )
}

export default Header;