export const mainURL = process.env.REACT_APP_API_URL;

export const joinameeting = mainURL + "/joinameeting";
export const joinARoomURL = mainURL + "/joinaroom";
export const createameeting = mainURL + "/createameeting";
export const signin = mainURL + "/login";  
export const register = mainURL + "/register";
export const meetingURL = mainURL + "/meeting"; // /:id
export const meetingWithIdURL = mainURL + "/meetingwithid"; // /:id
export const myMeetingURL = mainURL + "/mymeetings" // Auth
export const startURL = mainURL + "/start";
export const userRoomURL = mainURL + "/userroom";
export const deleteMeetingURL = mainURL + "/meeting" // /:id
export const profileURL = mainURL + "/profile";
