import postaci from "../../modules/postaci";
import { deleteMeetingURL } from '../../configurations/api_paths';

const deleteMeting = async ({
    id,
    //state,
    setState = () => { },
    callError = () => { }
}) => {
    const options = {
        url: `${deleteMeetingURL}/${id}`
    }
    try {
        const del = await postaci.delete(options);
        if (del) {
            
        }
    } catch (e) {
        callError(e)
    }
}

export default deleteMeting
