import { LOGOUT, UPDATE_USER } from "./actions.js";

// Provider , Consumer
const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case LOGOUT:
      console.log("LOGOUT");
      localStorage.clear();
      return {
        ...state,
        token: null,
        user: null,
      };
    default:
      return state;
  }
};

export default reducer;
