import "./App.css";
import "antd/dist/antd.css";
import { ThemeProvider } from "styled-components";
import theme from "./configurations/theme";
import Routes from "./configurations/routes";
import { BrowserRouter } from "react-router-dom";
import AppStyle from "./pages/style";
import Header from "./pages/Header";
import {  Suspense } from "react";

import Footer from "./pages/Footer";
import { ConfigProvider } from "antd";
import PageLoading from "./UI/PageLoading/PageLoading";
import { AppProvider } from "./Context/User/AppProvider";

function App() {

  return (
    <Suspense fallback={<PageLoading />}>
      <ConfigProvider>
        <ThemeProvider theme={theme}>
          <AppProvider>
            <BrowserRouter>
              <AppStyle>
                <div className="siteContainer">
                  <div className="bodyContainer">
                    <div className="mainContent">
                      <Header />

                      <div className="body">
                        <div className="mainContainer">
                          <Routes />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <footer className="footer">
                  <Footer />
                  {/* <center>
                <Button onClick={() => changeLanguage('en')}>EN</Button>
                <Button onClick={() => changeLanguage('de')}>DE</Button>
                <Button onClick={() => changeLanguage('tr')}>TR</Button>
              </center> */}
                </footer>
              </AppStyle>
            </BrowserRouter>
          </AppProvider>
        </ThemeProvider>
      </ConfigProvider>
    </Suspense>
  );
}
export default App;
