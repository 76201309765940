import Icon from "@ant-design/icons";

const FacebookSvg = () => (
  <svg
    width='10'
    height='18'
    viewBox='0 0 10 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.065 17.9972V9.80115H8.83L9.241 6.59215H6.065V4.54815C6.065 3.62215 6.323 2.98815 7.652 2.98815H9.336V0.12715C8.517 0.03915 7.693 -0.00285004 6.869 0.000149963C4.425 0.000149963 2.747 1.49215 2.747 4.23115V6.58615H0V9.79515H2.753V17.9972H6.065Z'
      fill='#838383'
    />
  </svg>
);

const PlusGlobeSvg = () => (
  <svg
    width='44'
    height='22'
    viewBox='0 0 44 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect x='0.5' y='0.5' width='43' height='21' rx='10.5' stroke='#838383' />
    <path
      d='M35.488 12.6C35.552 12.072 35.6 11.544 35.6 11C35.6 10.456 35.552 9.928 35.488 9.4H38.192C38.32 9.912 38.4 10.448 38.4 11C38.4 11.552 38.32 12.088 38.192 12.6H35.488ZM34.072 17.048C34.552 16.16 34.92 15.2 35.176 14.2H37.536C36.768 15.52 35.544 16.544 34.072 17.048ZM33.872 12.6H30.128C30.048 12.072 30 11.544 30 11C30 10.456 30.048 9.92 30.128 9.4H33.872C33.944 9.92 34 10.456 34 11C34 11.544 33.944 12.072 33.872 12.6ZM32 17.368C31.336 16.408 30.8 15.344 30.472 14.2H33.528C33.2 15.344 32.664 16.408 32 17.368ZM28.8 7.8H26.464C27.224 6.472 28.456 5.448 29.92 4.952C29.44 5.84 29.08 6.8 28.8 7.8ZM26.464 14.2H28.8C29.08 15.2 29.44 16.16 29.92 17.048C28.456 16.544 27.224 15.52 26.464 14.2ZM25.808 12.6C25.68 12.088 25.6 11.552 25.6 11C25.6 10.448 25.68 9.912 25.808 9.4H28.512C28.448 9.928 28.4 10.456 28.4 11C28.4 11.544 28.448 12.072 28.512 12.6H25.808ZM32 4.624C32.664 5.584 33.2 6.656 33.528 7.8H30.472C30.8 6.656 31.336 5.584 32 4.624ZM37.536 7.8H35.176C34.92 6.8 34.552 5.84 34.072 4.952C35.544 5.456 36.768 6.472 37.536 7.8ZM32 3C27.576 3 24 6.6 24 11C24 13.1217 24.8429 15.1566 26.3431 16.6569C27.086 17.3997 27.9679 17.989 28.9385 18.391C29.9091 18.7931 30.9494 19 32 19C34.1217 19 36.1566 18.1571 37.6569 16.6569C39.1571 15.1566 40 13.1217 40 11C40 9.94943 39.7931 8.90914 39.391 7.93853C38.989 6.96793 38.3997 6.08601 37.6569 5.34315C36.914 4.60028 36.0321 4.011 35.0615 3.60896C34.0909 3.20693 33.0506 3 32 3Z'
      fill='#838383'
    />
    <path
      d='M8.35156 11.6728H12.7744V16.0957C12.7744 16.4238 13.0479 16.7041 13.3828 16.7041C13.7178 16.7041 13.9981 16.4238 13.9981 16.0957V11.6728H18.4141C18.7422 11.6728 19.0225 11.3994 19.0225 11.0644C19.0225 10.7295 18.7422 10.4492 18.4141 10.4492H13.9981V6.0332C13.9981 5.70508 13.7178 5.4248 13.3828 5.4248C13.0479 5.4248 12.7744 5.70508 12.7744 6.0332V10.4492H8.35156C8.02344 10.4492 7.74316 10.7295 7.74316 11.0644C7.74316 11.3994 8.02344 11.6728 8.35156 11.6728Z'
      fill='#838383'
    />
  </svg>
);

const InstagramSvg = () => (
  <svg
    width='19'
    height='18'
    viewBox='0 0 19 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.168 0.00012207H5.16797C2.69319 0.00012207 0.667969 2.02445 0.667969 4.50012V13.5001C0.667969 15.9749 2.69319 18.0001 5.16797 18.0001H14.168C16.6427 18.0001 18.668 15.9749 18.668 13.5001V4.50012C18.668 2.02445 16.6427 0.00012207 14.168 0.00012207ZM9.66797 12.75C7.59662 12.75 5.91789 11.0706 5.91789 9.00012C5.91789 6.92877 7.59662 5.25005 9.66797 5.25005C11.7384 5.25005 13.418 6.92877 13.418 9.00012C13.418 11.0706 11.7384 12.75 9.66797 12.75ZM13.418 4.12505C13.418 4.74627 13.9211 5.25005 14.543 5.25005C15.1649 5.25005 15.668 4.74627 15.668 4.12505C15.668 3.50382 15.1649 3.00005 14.543 3.00005C13.9211 3.00005 13.418 3.50382 13.418 4.12505Z'
      fill='#838383'
    />
  </svg>
);

const TwitterSvg = () => (
  <svg
    width='21'
    height='16'
    viewBox='0 0 21 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18.301 3.99716C18.314 4.17216 18.314 4.34617 18.314 4.52016C18.314 9.84517 14.261 15.9812 6.85397 15.9812C4.57197 15.9812 2.45197 15.3202 0.667969 14.1722C0.991969 14.2092 1.30397 14.2222 1.64097 14.2222C3.52397 14.2222 5.25697 13.5862 6.64197 12.5012C4.87097 12.4642 3.38697 11.3042 2.87497 9.70817C3.12397 9.74516 3.37397 9.77017 3.63597 9.77017C3.99697 9.77017 4.35997 9.72017 4.69697 9.63317C2.84997 9.25917 1.46697 7.63816 1.46697 5.68016V5.63017C2.00397 5.92916 2.62697 6.11617 3.28697 6.14117C2.20197 5.41917 1.49097 4.18417 1.49097 2.78716C1.49097 2.03917 1.68997 1.35317 2.03897 0.755165C4.02197 3.19817 7.00297 4.79517 10.345 4.97017C10.283 4.67017 10.245 4.35916 10.245 4.04716C10.245 1.82716 12.041 0.019165 14.273 0.019165C15.433 0.019165 16.48 0.505165 17.216 1.29116C18.126 1.11617 18.998 0.779165 19.772 0.318165C19.473 1.25317 18.836 2.03917 18.001 2.53817C18.812 2.45017 19.598 2.22617 20.32 1.91417C19.772 2.71216 19.087 3.42316 18.301 3.99716Z'
      fill='#838383'
    />
  </svg>
);
const YoutubeSvg = () => (
  <svg
    width='23'
    height='16'
    viewBox='0 0 23 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.6353 0.731644C21.597 0.856946 22.5162 1.79225 22.6331 2.76766C23.0472 6.34238 23.0472 9.80878 22.6331 13.3824C22.5162 14.3578 21.597 15.2942 20.6353 15.4184C14.7709 16.1471 8.84018 16.1471 2.97745 15.4184C2.01486 15.2939 1.0957 14.3578 0.978752 13.3824C0.5647 9.8085 0.5647 6.34238 0.978752 2.76766C1.0957 1.79225 2.01486 0.856667 2.97745 0.731644C8.84018 0.00294484 14.7706 0.00294484 20.6353 0.731644ZM9.94952 4.36289V11.7883L15.5185 8.07572L9.94952 4.36289Z'
      fill='#838383'
    />
  </svg>
);

export const FacebookIcon = (props) => (
  <Icon component={FacebookSvg} {...props} />
);

export const PlusGlobeIcon = (props) => (
  <Icon component={PlusGlobeSvg} {...props} />
);

export const YoutubeIcon = (props) => (
  <Icon component={YoutubeSvg} {...props} />
);

export const InstagramIcon = (props) => (
  <Icon component={InstagramSvg} {...props} />
);

export const TwitterIcon = (props) => (
  <Icon component={TwitterSvg} {...props} />
);
