import asyncComponent from "../modules/AsyncFunc"

const authRoutes = [
    {
        exact: true,
        path: '/dashboard',
        component: asyncComponent(() => import("../pages/dashboard"))
    },
    {
        exact: true,
        path: '/createameeting',
        component: asyncComponent(() => import("../pages/CreateAMeeting/createameeting"))
    },
    {
        exact: true,
        path: '/profile',
        component: asyncComponent(() => import("../pages/Profile"))
    },
    {
        exact: true,
        path: '/calendar',
        component: asyncComponent(() => import("../pages/Calendar/CalendarPage"))
    }
]


export default authRoutes