import React, { useReducer, useContext } from "react";
import reducer from "./reducer";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    token:"sdsdf"
  });
  const value = { state, dispatch };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const useUser = () => {
  const context = useContext(AppContext);
  /*if (context === undefined) {
    throw new Error("useUser must be used within a AppProvider");
  }*/
  return context;
};

export { AppProvider, useUser };
